.btn-of-hero-sec-d2d {
  background-color: #89c000;
  transition: background-color 0.3s ease;
}

.btn-of-hero-sec-d2d:hover {
  background-color: #6d9702;
}

.btn-of-hero-sec-rfp {
  background-color: #c2b78c;
  transition: background-color 0.3s ease;
}

.btn-of-hero-sec-rfp:hover {
  background-color: #878063;
}

.greenTag {
  background-color: #89c000;
  width: max-content;
  padding: 1.25rem 1.75rem;
}

.row-2s {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 0px;
  padding: 0px;
}

.S1 {
  /* display: grid; */
  place-content: center;
  position: relative;
  overflow: hidden;

  --position: 50%;
}

.BAslider-1 {
  max-width: 555px;
  /* height: 80%; */
  /* aspect-ratio: 1/1; */
}

.BAslider-image {
  width: 555px;
  height: 379px;

  object-position: left;
}

.BAimage-before {
  object-fit: cover;
  position: absolute;
  inset: 0;
  width: var(--position);
  filter: grayscale(0%);
}

.BAslider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 555px;
  height: 379px;
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.BAslider-line {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  /* z-index: 10; */
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.BAslider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* z-index: 100; */
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
}

.S11 {
  display: grid;
  place-content: center;
  position: relative;
  overflow: hidden;
  --position: 50%;
}

.BAslider-1 {
  max-width: 555px;
}

.BAslider-image1 {
  width: 555px;
  height: 379px;

  object-position: left;
}

.BAimage-before1 {
  object-fit: cover;
  position: absolute;
  inset: 0;
  width: var(--position);
  filter: grayscale(0%);
}

.BAslider1 {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 555px;
  /* height: 100%; */
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.BAslider-line1 {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  /* z-index: 10; */
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.BAslider-button1 {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* z-index: 100; */
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
}

.EngineHerosectiontitle {
  align-self: stretch;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.WinContractsWithAllTheRightTools {
  /* width: 607px; */
  text-align: center;
}
